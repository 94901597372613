import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { RxCross1 } from "react-icons/rx";

import "./style.css";
import uploadImagePlaceHolder from "../../../../assets/images/uploadImagePlaceholder.svg";
import "../../style.css";
import { BiErrorCircle } from "react-icons/bi";
import ConfirmationModal from "../../ConfirmationModal";
import { useDispatch, useSelector } from "react-redux";
import {
  editeObservaion,
  refetchObs,
  singleObservation,
  updateObservationLocally,
} from "../../../../actions/projectActions";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useTranslation } from "react-i18next";
import EditGroupFamSpecie from "./editModules/EditGroupFamSpecie";
import { useEditObservationStore } from "../../../../lib/editObservation/useEditObservationData";
import EditObsSiteSegment from "./editModules/EditObsSiteSegment";
import EditImageIpload from "./editModules/EditImageIpload";
import EditObsType from "./editModules/EditObsType";
import EditCordsInput from "./editModules/EditCordsInput";
import EditDateTime from "./editModules/EditDateTime";
import ObservationTypePill from "../../ObservationTypePill";

import ModalsMap from "../../modalsMolecules/ModalsMap";
import { useGetCountryCoordinates } from "../../../../utils/useGetCoutryCoordinates";

dayjs.extend(customParseFormat);

const EditObservation = ({
  editData,

  closeIsEditModal,
}) => {
  const { t } = useTranslation();
  const {
    site,
    segment,
    displaySite,
    displaySegment,
    observation,
    obsId,
    classData,
    family,
    species,
    displayObserVationType,
    displayGroupe,
    displaySpecies,
    displayFamily,
    newLocalLong,
    newLocalLat,
    currentTime,
    seconds,
    newLat,
    newLong,
    setNewLat,
    setNewLong,
    convertedDate,
    note,
    deadOrAlive,
    prevImages,
    changeTracker,
    crawlWidth,
    // new nest
    newNestId,
    newNestCollector,
    newNestHighTideDist,
    newNestStatus,
    newNestEstHatchDate,
    newNestEstExhumDate,

    // Femal encounter
    newFemEncTagLeft,
    newFemEncTagRight,
    newFemEncLength,
    newFemEncWidth,

    setNote,
    setDisplayObservationType,
    setCrawlWidth,
    setPrevImages,
    setNewLocalLat,
    setNewLocalLong,
    setObsId,
    setDeadOrAlive,
    setChangeTracker,
    resetEditDataStore,
  } = useEditObservationStore();
  // siteId
  const pId = useSelector((state) => state.projectId);
  const { localProjectId } = pId;

  const [editModalState, setEditModalState] = useState(true);
  const [groupChangeTracker, setGroupChangeTracker] = useState(false);
  //****** */ End of data fetching and setting***********

  useEffect(() => {
    if (editData) {
      setObsId(editData.id);
      setNote(editData.note);
      setDeadOrAlive(editData.dead);

      const values = Object.values(editData.images);
      const filteredImages = values.filter(
        (value) => value !== "NULL" && value !== ""
      );
      setPrevImages(filteredImages);
    }
  }, [editData]);

  // delete single image

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [imageWasDeleted, setImgWasDeleted] = useState(false);
  const [trackDeleteImage, setTrackDeleteImage] = useState(false);
  // set coordinates
  const { countryLat, countryLong, loadingCountryCoords } =
    useGetCountryCoordinates();
  useEffect(() => {
    if (!loadingCountryCoords && countryLat) {
      if (editData) {
        if (Number(editData.coordX) === 0) {
          setNewLocalLat(countryLat);
          setNewLocalLong(countryLong);
        } else {
          setNewLocalLat(editData.coordX);
          setNewLocalLong(editData.coordY);
        }
      }
    }
  }, [editData, loadingCountryCoords, countryLat, countryLong, localProjectId]);

  const cordRef = useRef();

  const dispatch = useDispatch();
  // const [setsetLocalTimeForUpdat, setsetsetLocalTimeForUpdate] = useState();

  const handleSaveEdits = async () => {
    const data = {
      obsId,
      newLocalLong,
      newLocalLat,
      site,
      segment,
      observation,
      classData,
      family,
      species,
      prevImages,
      convertedDate,
      time: currentTime + `:${seconds}`,
      dead: deadOrAlive,
    };
    const timeStr = currentTime + `:${seconds}`;
    const dateStr = convertedDate;

    // Splitting time into hours, minutes, and seconds
    const [hours, minutes, secondss] = timeStr.split(":");

    // Splitting date into year, month, and day
    const [year, month, day] = dateStr.split("-");

    // Setting the desired timezone
    // Adjusting the UTC offset
    const dt = new Date(
      Date.UTC(year, month - 1, day, hours, minutes, secondss)
    );

    // Creating the final object
    const localdateObject = {
      date: dt.toISOString().slice(0, 23),
      timezone_type: 3,
      timezone: "UTC",
    };

    const updtedObservationData = {
      id: editData.id,
      projectId: editData.projectId,
      projectName: editData.projectName,
      idInaturalist: editData.idInaturalist,
      coordX: newLocalLat,
      coordY: newLocalLong,
      note: editData.note,
      alpha: editData.alpha,
      collector: editData.collector,
      images: {
        img1: prevImages[0] || "",
        img2: prevImages[1] || "",
        img3: prevImages[2] || "",
        img4: prevImages[3] || "",
      },
      dead: deadOrAlive,
      status: editData.status,
      TypeObservationId: observation,
      TypeObservation: displayObserVationType,
      specieId: species,
      specie: displaySpecies,
      familyId: family,
      family: displayFamily,
      groupId: classData,
      group: displayGroupe,
      user: editData.user,
      date: localdateObject,
      updatedate: null,
      segment: displaySegment,
      site: displaySite,
    };

    dispatch(editeObservaion(data));
    dispatch(updateObservationLocally(updtedObservationData));

    resetEditDataStore();
    closeIsEditModal();
  };

  // confirm exit edit
  const refetchObsList = useSelector((state) => state.setRefetchObsList);
  const { refetchObsValue } = refetchObsList;
  const [confirmExit, setConfirmExit] = useState(false);
  const handleExitEdit = () => {
    if (trackDeleteImage) {
      dispatch(refetchObs({ refetchObsValue: !refetchObsValue }));
    }
    resetEditDataStore();
    closeIsEditModal();
    setConfirmExit(true);
  };
  const handleCloseConfirm = () => {
    setConfirmExit(false);
  };

  useEffect(() => {
    dispatch(singleObservation({ singleObsId: editData.id }));
  }, [editData, dispatch]);

  // input tracker

  const handleShowConfirmModal = () => {
    if (changeTracker) {
      setConfirmExit(true);
    } else {
      //
      if (imageWasDeleted) {
        resetEditDataStore();
        handleSaveEdits();
        closeIsEditModal();
      } else {
        handleExitEdit();
      }
    }
  };

  return (
    <div className="mvp__modals">
      <Modal
        show={true}
        // showModal

        backdrop="static"
        centered
        className="mx-auto mvp__modals edit__observation "
        keyboard={false}
        size="lg"
        dialogClassName="modal-size"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header
          className="bgPrimary modal__header "
          style={{
            color: "white",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Modal.Title className="modal__header__title">
            {t("editObs")}
          </Modal.Title>
          <div className="">
            <span
              className="pointer"
              onClick={() => {
                handleShowConfirmModal();
              }}
            >
              <RxCross1 className="close-modal-icon" />
            </span>
          </div>
        </Modal.Header>

        {editModalState ? (
          <>
            <div
              className="modal-top"
              style={{ borderBottom: "0.3px solid #ebf0ec" }}
            >
              <div
                className="modal-map"
                style={{ width: "50%", height: "100%", background: "grey" }}
              >
                {newLocalLat && newLocalLong && (
                  <ModalsMap
                    displayObserVationType={displayObserVationType}
                    newLocalLat={newLocalLat}
                    newLat={newLat}
                    newLong={newLong}
                    newLocalLong={newLocalLong}
                    setNewLocalLat={setNewLocalLat}
                    setNewLocalLong={setNewLocalLong}
                    setNewLat={setNewLat}
                    setNewLong={setNewLong}
                  />
                )}
              </div>

              <div
                className="modal-imgs "
                style={{ width: "50%", height: "100%", position: "relative" }}
              >
                {prevImages && prevImages.length !== 0 ? (
                  <>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        position: "relative",
                        backgroundImage: `url(${prevImages[0]})`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: " center center",
                        backgroundSize: "cover",
                      }}
                    />
                  </>
                ) : (
                  <>
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{
                        height: "100%",
                        width: "100%",
                      }}
                    >
                      <div
                        className="text-center"
                        style={{
                          color: "#096DBB",

                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setEditModalState(!editModalState);
                        }}
                      >
                        <img
                          src={uploadImagePlaceHolder}
                          style={{
                            height: "100%",
                            width: "100%",
                            cursor: "pointer",
                          }}
                          alt=""
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>

              {prevImages && prevImages.length !== 0 && (
                <div
                  className="modal-photo-btn"
                  onClick={() => {
                    setEditModalState(!editModalState);
                  }}
                >
                  {prevImages.length > 1 ? (
                    <>
                      <span className="mx-1">+</span> {prevImages?.length - 1}
                    </>
                  ) : (
                    ""
                  )}

                  {prevImages.length > 1 ? (
                    <span className="mx-1">more</span>
                  ) : (
                    <>
                      {prevImages.length} <span className="mx-1">photo</span>
                    </>
                  )}
                  <svg
                    width="7"
                    height="12"
                    viewBox="0 0 7 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.276974 0.159762C0.0691454 0.372745 0.0691099 0.718125 0.27701 0.931144L5.22321 5.99987L0.276974 11.0689C0.0691454 11.2818 0.0691099 11.6272 0.27701 11.8402C0.484874 12.0533 0.821864 12.0533 1.02973 11.8402L6.35231 6.38555C6.45212 6.28325 6.50819 6.14453 6.50819 5.99987C6.50819 5.85522 6.45209 5.71645 6.35227 5.6142L1.02969 0.1598C0.821864 -0.0532551 0.484839 -0.053256 0.276974 0.159762Z"
                      fill="white"
                    />
                  </svg>
                </div>
              )}
            </div>
            <div>
              <div
                className="modalBody container-fluid "
                // style={{ width: "100%" }}
              >
                <div className="row ">
                  <div className="col-12 px-4 pt-2 ">
                    <span className="f-bold me-4" style={{ fontSize: ".9rem" }}>
                      Obs {editData && editData.id}
                    </span>
                    <ObservationTypePill
                      displayObserVationType={displayObserVationType}
                    />
                  </div>
                  <div className="col-5 text-end"></div>
                </div>
                <div className="row px-3 modal__obs__details__row projectName">
                  <div className="col-5 modal__detail__title">
                    {" "}
                    {t("projectName")}
                  </div>
                  <div className="col-7 modal__title__value author">
                    {editData && editData.projectName}
                  </div>
                </div>
                <div className="row px-3 modal__obs__details__row projectName">
                  <div className="col-5 modal__detail__title">
                    {t("author")}
                  </div>
                  <div className="col-7 modal__title__value author">
                    {editData && editData.user}
                  </div>
                </div>

                <EditDateTime editData={editData} />
                <EditCordsInput cordRef={cordRef} />

                <EditObsSiteSegment editData={editData} />

                <EditObsType
                  editData={editData}
                  setGroupChangeTracker={setGroupChangeTracker}
                />

                <EditGroupFamSpecie
                  editData={editData}
                  groupChangeTracker={groupChangeTracker}
                  setGroupChangeTracker={setGroupChangeTracker}
                />

                {/* DYNAMIC FORM */}
                <div>
                  <div>
                    {/* {(displayObserVationType === obsertionTypes.nest.VE ||
                      displayObserVationType === obsertionTypes.nest.VF) && (
                      <EditNewNest editData={editData} />
                    )}

                    {(displayObserVationType === obsertionTypes.female.VE ||
                      displayObserVationType === obsertionTypes.female.VF) && (
                      <EditFemaleEncounter editData={editData} />
                    )} */}

                    {/* Crawl Width */}
                    {/* {(displayObserVationType === obsertionTypes.female.VE ||
                      displayObserVationType === obsertionTypes.female.VF) && (
                      <CreateNewFemaleEncounter
                        errors={null}
                        setChangeTracker={setChangeTracker}
                        setCrawlWidth={setCrawlWidth}
                        crawlWidth={crawlWidth}
                      />
                    )} */}
                    {/* Notes */}
                    <div>
                      <div className="row px-3 modal__obs__details__row mt-1">
                        <div className="col-5 modal__detail__title">Notes</div>
                        <div className="col-7 modal__title__value">
                          <input
                            type="text"
                            id="notes"
                            name="notes"
                            className={`crud_select crud-input`}
                            style={{ width: "101%" }}
                            value={note}
                            onChange={(e) => {
                              setNote(e.target.value);
                              setChangeTracker(true);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="modal-footers mt-2"
              style={{
                borderRadius: "8px",
              }}
            >
              <div
                className="d-flex justify-content-end"
                style={{
                  width: "100%",
                }}
              >
                <div
                  className="modal_obs__btn edit"
                  onClick={() => {
                    handleShowConfirmModal();
                  }}
                >
                  {t("cancelBtn")}
                </div>
                <div
                  className="modal_obs__btn edit validate"
                  onClick={() => {
                    // setShowModal(false);
                    handleSaveEdits();
                  }}
                >
                  {t("saveChanges")}
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <Modal.Body>
              <EditImageIpload
                editData={editData}
                editModalState={editModalState}
                setEditModalState={setEditModalState}
                userInfo={userInfo}
                setTrackDeleteImage={setTrackDeleteImage}
                setImgWasDeleted={setImgWasDeleted}
              />
            </Modal.Body>
          </>
        )}
      </Modal>

      {/* Confirm edit exist */}
      {confirmExit && (
        <ConfirmationModal
          cofirmSourceIcon={
            <BiErrorCircle
              style={{
                fontSize: "3rem",
                fontWeight: "bold",
              }}
              color="red"
            />
          }
          message={`${t("saveChanges")} ?`}
          details={t("lostUnsaved")}
          btn1Text={t("dontSave")}
          btn2Text={t("saveBtn")}
          color={"#404040"}
          handleHideConfirmationModal={handleExitEdit}
          handleConfirmAction={handleSaveEdits}
          closeConfirm={handleCloseConfirm}
        />
      )}
    </div>
  );
};
export default EditObservation;
