import {
  MapContainer,
  ZoomControl,
  TileLayer,
  Marker,
  Popup,
} from "react-leaflet";
import { useMap } from "react-leaflet";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CustomSuccess from "../../Alerts/CustomSuccess";
import CustomError from "../../Alerts/CustomError";
import MapLoading from "../../LoadingError/MapLoading";

import "./styles.css";

import { useSelectedIconList } from "../../../utils/MapMarkersList";
import Loading2 from "../../LoadingError/Loading2";
import { MarkerPopup } from "../MarkerPopup/MarkerPopup";
import { openPopup } from "../../../actions/projectActions";

import PixiOverlay from "react-leaflet-pixi-overlay";
import NoData from "./NoData";
import { pan$, setPan } from "../../../services/rxjs";

import { useRef } from "react";

import useCustomPins from "./useCustomPins";
import { useCreateNewFemaleEncounter } from "../../../lib/createObs/useCreateObservationData";
import { useTranslation } from "react-i18next";
import SetMap from "../../modals/modalsMolecules/SetMap";
import { useEditObservationStore } from "../../../lib/editObservation/useEditObservationData";

function FlyTO({ location, project }) {
  const { selectedIconList } = useSelectedIconList();
  const changeMarker = useSelector((state) => state.changeSelectedMarker);
  const { markerDetails } = changeMarker;
  console.log(markerDetails, "markerDetails");

  const [rst, setRst] = useState(false);

  const map = useMap();

  useEffect(() => {
    pan$.subscribe((result) => setRst(result));
  }, []);

  if (markerDetails.id !== "" && rst) {
    map.flyTo(location, 14);
    setPan(false);
  }

  return location ? (
    <Marker
      position={location}
      icon={
        project?.TypeObservationId === 7
          ? selectedIconList.threat
          : project?.TypeObservationId === 4
          ? selectedIconList.animal
          : project?.TypeObservationId === 5
          ? selectedIconList.presence
          : project?.TypeObservationId === 6
          ? selectedIconList.flash
          : project?.TypeObservationId === 2
          ? selectedIconList.crawl
          : project?.TypeObservationId === 1
          ? selectedIconList.nest
          : project?.TypeObservationId === 3
          ? selectedIconList.fencounter
          : selectedIconList.others
      }
    >
      <Popup>
        <MarkerPopup singleObsId={project.id} />
      </Popup>
    </Marker>
  ) : null;
}
function OpenPopupTrigger({ location, project }) {
  const markerRef = useRef(null);

  useEffect(() => {
    if (markerRef.current && markerRef.current?.leafletElement) {
      markerRef.current.leafletElement.openPopup();
    }
  }, []);

  const { selectedIconList } = useSelectedIconList();
  const popupInfo = useSelector((state) => state.openPopup);
  const { popupdetails } = popupInfo;

  const map = useMap();

  if (popupdetails.id !== "") {
    map.setView(location, map.getZoom());
    map.stop();
  }

  return location ? (
    <Marker
      ref={markerRef}
      position={location}
      icon={
        project?.TypeObservationId === 7
          ? selectedIconList.threat
          : project?.TypeObservationId === 4
          ? selectedIconList.animal
          : project?.TypeObservationId === 5
          ? selectedIconList.presence
          : project?.TypeObservationId === 6
          ? selectedIconList.flash
          : project?.TypeObservationId === 2
          ? selectedIconList.crawl
          : project?.TypeObservationId === 1
          ? selectedIconList.nest
          : project?.TypeObservationId === 3
          ? selectedIconList.fencounter
          : selectedIconList.others
      }
    >
      <Popup>
        <MarkerPopup singleObsId={project?.id} />
      </Popup>
    </Marker>
  ) : null;
}

function CenterMapClick({ location }) {
  const map = useMap();

  if (location[0]) {
    map.setView(location, 7);
  }

  return null;
}

function ClosePopup() {
  const map = useMap();

  // Close popup when map zoom changes
  map.on("zoom", () => {
    map.closePopup();
  });

  return null;
}

function LeafletMap({
  countryLong,
  countryLat,
  observationList,
  loading,
  projectFetchLoading,
  toggleMapCcenter,
  refetchLoading,
  loadingCountryCoords,
}) {
  const popupInfo = useSelector((state) => state.openPopup);
  const { popupdetails } = popupInfo;
  console.log(observationList, "observationList");

  const createObs = useSelector((state) => state.createObservation);
  const { obsCreateLoading } = createObs;

  const editeObservation = useSelector((state) => state.editeObservation);
  const { updateLoading } = editeObservation;

  // select delete state from redux store to and access delete confirm mesage
  const deleteObservation = useSelector((state) => state.deleteObservation);
  const { deleteLoading } = deleteObservation;

  const validObs = useSelector(
    (state) => state.validateUnvalidatedObservations
  );
  const { validateLoading } = validObs;
  // Notif state

  // change marker
  const changeMarkerr = useSelector((state) => state.changeSelectedMarker);
  const { markerDetails } = changeMarkerr;

  // Success notifications
  const successMessage = useSelector((state) => state.setSuccessMessage);
  const {
    createObsSuccessNotif,
    updateObsSuccessNotif,
    deleteObsSuccessNotif,
    validateObsSuccessNotif,
    deleteObsImageNotif,
  } = successMessage;

  // error notif
  const errorMessage = useSelector((state) => state.setErrorMessage);
  const {
    createObsErrorNotif,
    updateObsErrorNotif,
    deleteObsErrorNotif,
    validateObsErrorNotif,
  } = errorMessage;

  // -------------Fetch observations------------------
  const { mapData } = useCustomPins(observationList, openPopup);
  // const [Observation, setObservation] = useState();

  // ********ENd modals states and FUnctions
  // Event handler for map panning start
  const { t } = useTranslation();
  const { femaleCreated } = useCreateNewFemaleEncounter();

  if (loading || projectFetchLoading || loadingCountryCoords) {
    return <MapLoading />;
  }
  return (
    <div>
      <>
        {createObsSuccessNotif &&
        createObsSuccessNotif === true &&
        !femaleCreated ? (
          <CustomSuccess
            message={`${t("listview.notificationMessages.obsCreated")}`}
          />
        ) : (
          ""
        )}
        {updateObsSuccessNotif && updateObsSuccessNotif === true ? (
          <CustomSuccess message={"Success! Observation updated."} />
        ) : (
          ""
        )}
        {deleteObsSuccessNotif && deleteObsSuccessNotif === true ? (
          <CustomSuccess message={"Success! Observation deleted."} />
        ) : (
          ""
        )}
        {validateObsSuccessNotif && validateObsSuccessNotif === true ? (
          <CustomSuccess message={"Success! Observation validated."} />
        ) : (
          ""
        )}
        {deleteObsImageNotif && deleteObsImageNotif === true ? (
          <CustomSuccess message={"Success! Image deleted."} />
        ) : (
          ""
        )}

        {/* Error notiff */}
        {createObsErrorNotif && createObsErrorNotif === true ? (
          <CustomError message={"An error occured"} />
        ) : (
          ""
        )}
        {updateObsErrorNotif && updateObsErrorNotif === true ? (
          <CustomError message={"An error occured"} />
        ) : (
          ""
        )}
        {deleteObsErrorNotif && deleteObsErrorNotif === true ? (
          <CustomError message={"An error occured"} />
        ) : (
          ""
        )}
        {validateObsErrorNotif && validateObsErrorNotif === true ? (
          <CustomError message={"An error occured"} />
        ) : (
          ""
        )}

        {obsCreateLoading && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100vh",
              overflow: "auto",
              transition: "all 0.5s ease-in-out",
              zIndex: "99999",
            }}
          >
            <Loading2 />
          </div>
        )}
        {updateLoading && (
          <div>
            <Loading2 />
          </div>
        )}
        {deleteLoading && (
          <div>
            <Loading2 />
          </div>
        )}
        {validateLoading && (
          <div>
            <Loading2 />
          </div>
        )}
        {refetchLoading && (
          <div>
            <Loading2 />
          </div>
        )}
      </>
      {!loading &&
        !projectFetchLoading &&
        observationList &&
        observationList.length === 0 && <NoData />}

      {observationList && observationList !== "undefined" && (
        <div className="map_content ">
          <MapContainer
            preferCanvas
            maxZoom={100}
            minZoom={0}
            center={[
              observationList[0]?.coordX
                ? observationList[0].coordX
                : countryLat,
              observationList[0]?.coordY
                ? observationList[0].coordY
                : countryLong,
            ]}
            zoom={9}
            zoomControl={false}
            style={{ width: "100vw", zIndex: "1" }}
            className="leaflet-container"
            key={countryLat}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              noWrap="true"
            />
            {/* {newLocalLat && newLocalLong && (
              <SetMap
                location={[newLocalLat, newLocalLong]}
                lat={newLocalLat}
                long={newLocalLong}
                typeObservation={displayObserVationType}
              />
              // <FlyTO
              //   location={[newLocalLat, newLocalLong]}
              //   project={observationList[0]}
              // />
            )} */}

            <div className="">
              <ZoomControl
                position="bottomright"
                zoomInText="+"
                zoomOutText="-"
              />
            </div>
            <PixiOverlay markers={mapData}></PixiOverlay>

            {markerDetails.id !== "" && (
              <>
                <ClosePopup />
                <FlyTO
                  location={[
                    observationList[markerDetails.index]?.coordX || countryLat,
                    observationList[markerDetails.index]?.coordY || countryLong,
                  ]}
                  project={observationList[markerDetails?.index]}
                />
              </>
            )}
            {toggleMapCcenter && (
              <CenterMapClick
                location={[
                  observationList[0]?.coordX
                    ? observationList[0].coordX
                    : countryLat,
                  observationList[0]?.coordY
                    ? observationList[0].coordY
                    : countryLong,
                ]}
              />
            )}
            {popupdetails.id !== "" && (
              <>
                <ClosePopup />
                <OpenPopupTrigger
                  location={[
                    observationList[popupdetails.index]?.coordX || countryLat,
                    observationList[popupdetails.index]?.coordY || countryLong,
                  ]}
                  project={observationList[popupdetails?.index]}
                />
              </>
            )}

            {/* <EventTracker /> */}
          </MapContainer>
        </div>
      )}
    </div>
  );
}
export default LeafletMap;
