import React from "react";
import { MapContainer, TileLayer, useMapEvents } from "react-leaflet";
import ModalMarker from "../../map/leafletMap/ModalMarker";
import "../style.css";
import SetMap from "./SetMap";

const ClickLogger = ({
  setNewLocalLat,
  setNewLocalLong,
  setNewLat,
  setNewLong,
}) => {
  useMapEvents({
    mousedown: (e) => {
      e.originalEvent.stopPropagation();
      e.originalEvent.preventDefault();
      setNewLocalLat(Number(e.latlng.lat));
      setNewLocalLong(Number(e.latlng.lng));
      setNewLat(Number(e.latlng.lat));
      setNewLong(Number(e.latlng.lng));
    },
  });
  return null;
};

const ModalsMap = ({
  displayObserVationType,
  newLocalLat,
  newLat,
  newLong,
  newLocalLong,
  setNewLocalLat,
  setNewLocalLong,
  setNewLat,
  setNewLong,
}) => {
  return (
    <MapContainer
      center={[newLocalLat, newLocalLong]}
      zoom={9}
      minZoom={1}
      scrollWheelZoom={true}
      zoomControl={false}
      layer-type="base"
      style={{ width: "100%", height: "100%", zIndex: "9999" }}
      whenCreated={(map) => {
        map.on("click", (e) => {
          console.log("Map clicked (from whenCreated):", e.latlng);
        });
      }}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      />
      {newLat && newLong && (
        <SetMap
          location={[newLat, newLong]}
          lat={newLat}
          long={newLong}
          typeObservation={displayObserVationType}
        />
      )}
      <ModalMarker observation={null} lat={newLocalLat} long={newLocalLong} />
      <ClickLogger
        setNewLocalLat={setNewLocalLat}
        setNewLocalLong={setNewLocalLong}
        setNewLat={setNewLat}
        setNewLong={setNewLong}
      />
    </MapContainer>
  );
};

export default ModalsMap;
